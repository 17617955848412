import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';

const AppDiagnosticsPage = lazy(() => import(/*webpackChunkName: 'AppDiagnosticsPage' */
    'components/AppDiagnosticsPage/AppDiagnosticsPage'));
const AuthRedirect = lazy(() => import(/*webpackChunkName: 'AuthRedirect' */ 'components/AuthRedirect/AuthRedirect'));
const DetailReportsContainer = lazy(() => import(/* webpackChunkName: 'DetailReportContainer' */
    'components/PayStatements/DetailReport/DetailReportsContainer'));
const DirectDeposit = lazy(() => import(/* webpackChunkName: 'DirectDeposit' */ 'components/DirectDeposit/DirectDeposit'));
const ExpensesContainer = lazy(() => import(/* webpackChunkName: 'ExpensesContainer' */
    'components/PayStatements/ExpensePayments/ExpensesContainer'));
const FederalW4 = lazy(() => import(/* webpackChunkName: 'FederalW4' */ 'components/W4/FederalW4/FederalW4'));
const PageNotFound = lazy(() => import(/* webpackChunkName: 'PageNotFound' */ 'components/PageNotFound/PageNotFound'));
const PaychecksContainer = lazy(() => import(/* webpackChunkName: 'PaychecksContainer' */
    'components/PayStatements/Paychecks/PaychecksContainer'));
const PayStatementsContainer = lazy(() => import(/* webpackChunkName: 'PayStatementsContainer' */
    'components/PayStatements/PayStatementsContainer'));
const StateW4 = lazy(() => import(/* webpackChunkName: 'StateW4' */ 'components/W4/StateW4/StateW4'));

const routes = [
    {
        path: '/',
        component: () => <Redirect to='paystatements/paychecks' />,
        exact: true,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        path: '/paystatements',
        component: () => <Redirect to='paychecks' />,
        exact: true,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        title: 'Pay Statements',
        path: '/paystatements',
        component: PayStatementsContainer,
        exact: false,
        showInNavMenu: true,
        subRoutes: [
            {
                title: 'Paychecks',
                path: '/paychecks',
                component: PaychecksContainer,
                exact: true,
                isTab: true,
            },
            {
                title: 'Activity/Details Reports',
                path: '/activity-details-reports',
                component: DetailReportsContainer,
                exact: true,
                isTab: true,
            },
            {
                title: 'Expense Payments',
                path: '/expense-payments',
                component: ExpensesContainer,
                exact: true,
                isTab: true,
            },
            {
                title: 'Page Not Found',
                path: '*',
                component: PageNotFound,
                exact: false,
            },
            {
                title: 'Paperless Pay (Historical LUS)',
                path: 'https://paperlesspay.talx.com/americanairlines/',
                showInNavMenu: true,
            },
        ],

    },
    {
        title: 'Direct Deposit',
        path: '/direct-deposit',
        component: DirectDeposit,
        exact: true,
        showInNavMenu: true,
        subRoutes: [],
    },
    {
        title: 'Federal Form W-4',
        path: '/w4-federal',
        component: FederalW4,
        exact: true,
        showInNavMenu: true,
        subRoutes: [],
    },
    {
        title: 'AppDiagnosticsPage',
        path: '/epays-adp',
        component: AppDiagnosticsPage,
        exact: true,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        title: 'AuthRedirect',
        path: '/authredirect',
        component: AuthRedirect,
        exact: true,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        title: 'Page Not Found',
        path: '*',
        component: PageNotFound,
        exact: false,
        showInNavMenu: false,
        subRoutes: [],
    },
];

export default routes;