import React, {Suspense, useEffect, useState} from 'react';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {reactPlugin} from 'services/appInsights';

import {Col, Row} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

import {isTierDevelopmentOrStaging} from 'utilities/checkCurrentTier';
import useAuthentication from 'hooks/useAuthentication';
import {useAppContext} from 'contexts/AppContext';
import Error from 'components/Shared/Error/Error';
import Footer from 'components/Shared/Footer/Footer';
import Header from 'components/Shared/Header/Header';
import Loader from 'components/Shared/Loader/Loader';
import NavContainer from 'components/NavContainer/NavContainer';
import Routes from 'components/App/RoutingComponents/Routes';
import SessionData from 'components/Shared/SessionData/SessionData';
import TimeoutModal from 'components/App/TimeoutModal/TimeoutModal';
import CurrentAdminAnnouncement from 'components/App/CurrentAdminAnnouncement/CurrentAdminAnnouncement';

import 'assets/icons/icons.css';
import 'styles/custom.scss';
import './App.scss';

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const {allowAppAccess, errorDetails, handleSsoLogout, isAppAuthSequenceComplete, setAllowAppAccess} = useAppContext();

    const allowedSecondsUntilWarning = isTierDevelopmentOrStaging() ? 300 : 600;
    const allowedSecondsUntilSessionEnd = 120;

    const listOfPaths = ['/paystatements/paychecks', '/paystatements/activity-details-reports', '/paystatements/expense-payments'];

    useEffect(() => {
        if (allowAppAccess) {
            setIsLoading(false);
        }
        
    }, [allowAppAccess]);

    return (
        <React.Fragment>
            <div className='App'>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <div
                        className='app-body max-width mx-auto'
                        data-app-version={process.env.REACT_APP_VERSION}
                    >
                        <Router>
                            {allowAppAccess && isAppAuthSequenceComplete &&
                                <TimeoutModal 
                                    allowedSecondsUntilWarning={allowedSecondsUntilWarning}
                                    allowedSecondsUntilSessionEnd={allowedSecondsUntilSessionEnd}
                                    endSession={handleSsoLogout}
                                />
                            }
                            <Header onClickSsoLogout={handleSsoLogout}/>
                            {isLoading ?
                                <Loader/>
                                :
                                <div className='py-1 px-3'>
                                    <SessionData />  
                                    {listOfPaths.includes(window.location.pathname) &&
                                    <CurrentAdminAnnouncement/> 
                                    }  
                                    <Error  
                                        displayType={errorDetails.displayType} 
                                        errorMessage={errorDetails.errorMessage} 
                                        epaysCode={errorDetails.epaysCode}
                                        supportId={errorDetails.supportId}
                                    />       
                                    <Row className='py-1 mb-5'>
                                        <Col lg={8} xl={9}>
                                            <Suspense fallback={<Loader/>}>
                                                <Routes />
                                            </Suspense>
                                        </Col>
                                        <Col 
                                            className='d-none d-lg-block'
                                            lg={4}
                                            xl={3}
                                        >
                                            <NavContainer/>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Footer/>
                        </Router>
                    </div>
                </AppInsightsContext.Provider>
            </div>
        </React.Fragment>
    );
};

export default App;