import PropTypes from 'prop-types';
import React from 'react';

import Help from 'components/NavContainer/Help/Help';
import Navigation from 'components/NavContainer/Navigation/Navigation';

const NavContainer = props => {
    NavContainer.propTypes = {
        handleHideMenu: PropTypes.func,
    };

    NavContainer.defaultProps = {
        handleHideMenu: () => {},
    };

    const {handleHideMenu} = props;

    return (
        <React.Fragment>
            <div>
                <Navigation onClickHideMenu={handleHideMenu}/>
            </div>
            <div className='my-3'>
                <Help/>
            </div>
        </React.Fragment>
    );
};

export default NavContainer;