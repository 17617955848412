import React from 'react';
import './Loader.scss';
import loadingGif from 'assets/images/loading_sm.gif';

const Loader = () => {
    return (
        <React.Fragment>
            <div className="loader">
                <div className="loader-content">
                    <div className="loader-img">
                        <img src={loadingGif} alt='loading...'/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Loader;