const routesExternal = [
    {
        title: 'State Form W-4',
        path: 'https://newjetnet.aa.com/docs/DOC-20718',
        showInNavMenu: true,
    },
    {
        title: 'Online W-2',
        path: process.env.REACT_APP_EPAYS3_NEWJETNET_URL_RIGHT_PANEL,
        showInNavMenu: true,
    },
    {
        title: 'Employment Verification',
        path: 'https://theworknumber.com/',
        showInNavMenu: true,
    },
];

export default routesExternal;