import React from 'react';
import {
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';

import routes from 'routes/routes';
import {useAppContext} from 'contexts/AppContext';
import {DirectDepositProvider} from 'contexts/DirectDepositContext';
import GeneralErrorPage from 'components/GeneralErrorPage/GeneralErrorPage';

const Routes = () => {
    const {allowAppAccess} = useAppContext();
    return (
        <React.Fragment>
            <div className='app-routing-components'>
                {
                    allowAppAccess &&
                        <Switch>
                            {
                                routes.map((route) => {
                                    const Component = route.component;

                                    if (route.title === 'Direct Deposit') {
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact={route.exact}
                                                render={(props) => 
                                                    <DirectDepositProvider>
                                                        <Component 
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...props}
                                                            subRoutes={route.subRoutes}
                                                        />
                                                    </DirectDepositProvider>
                                                }
                                            />
                                        );
                                    }

                                    return (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact={route.exact}
                                            render={(props) => 
                                                <Component 
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...props}
                                                    subRoutes={route.subRoutes}
                                                />
                                            }
                                        />
                                    );
                                })
                            }
                        </Switch>
                }
                {
                    !allowAppAccess &&
                        <Switch>
                            <Route
                                path='/error'
                                exact
                                render={() => <GeneralErrorPage/>}  
                            />
                            <Route 
                                path='/*'
                                render={() => <Redirect to='/error' />}
                            />
                            <Redirect to='/error'/>
                        </Switch>
                }
            </div>
        </React.Fragment>
    );
};

export default Routes;