/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';

import axiosInstance from 'services/axiosConfig';

const TimeoutModal = (props) => {
    TimeoutModal.propTypes = {
        allowedSecondsUntilWarning: PropTypes.number.isRequired,
        allowedSecondsUntilSessionEnd: PropTypes.number.isRequired,
        endSession: PropTypes.func.isRequired,
    };
    
    const currentTime = () => Math.floor(new Date().getTime() / 1000);
    const {allowedSecondsUntilWarning, allowedSecondsUntilSessionEnd, endSession} = props;
    
    const [secondsUntilWarning, setSecondsUntilWarning] = useState();
    const [showTimeoutModalState, setShowTimeoutModalState] = useState(false);
    let showTimeoutLogical = false;

    const displayMinutesLeft = Math.floor(secondsUntilWarning / 60).toString().padStart(2, '0');
    const displaySecondsLeft = Math.floor(secondsUntilWarning % 60).toString().padStart(2, '0');


    const warningTimerRef = useRef(null);

    const [warningTime, setWarningTime] = useState();
    const [logoutTime, setLogoutTime] = useState();
    
    const beginIdleTimer = () => {
        const now = currentTime();
        setWarningTime(now + allowedSecondsUntilWarning);
    };

    const continueSession = () => {
        clearIdleTimer();
        showTimeoutLogical = false;
        setShowTimeoutModalState(false);
        beginIdleTimer();
    };

    const clearIdleTimer = () => {
        clearTimeout(warningTimerRef.current);
    };

    const updateIdleTimer = () => {
        let deltaSeconds = null;
        const now = currentTime();

        if (!showTimeoutLogical && now < warningTime) {
            deltaSeconds = (warningTime - now);

            warningTimerRef.current = setTimeout(() => {
                setSecondsUntilWarning(deltaSeconds);
                updateIdleTimer();
            }, 1000);
        } else if (!showTimeoutLogical && now >= warningTime) {
            deltaSeconds = (logoutTime - now);
            warningTimerRef.current = setTimeout(() => {
                setShowTimeoutModalState(true);
                showTimeoutLogical = true;
                setSecondsUntilWarning(deltaSeconds);
                updateIdleTimer();
            }, 1000);
        } else if (showTimeoutLogical && now >= warningTime && now <= logoutTime) {
            deltaSeconds = (logoutTime - now);
            warningTimerRef.current = setTimeout(() => {
                setSecondsUntilWarning(deltaSeconds);
                updateIdleTimer();
            }, 1000);
        } else if (now > logoutTime) {
            endSession();
        }
    };

    useEffect(() => {
        setLogoutTime(warningTime + allowedSecondsUntilSessionEnd);
    }, [warningTime]);

    useEffect(() => {
        updateIdleTimer();
    }, [logoutTime]);

    useEffect(() => {
        axiosInstance.interceptors.request.use((config) => {
            clearIdleTimer();
            return config;
        }, (error) => {
            beginIdleTimer();
            return Promise.reject(error);
        });
    
        axiosInstance.interceptors.response.use((response) => {
            beginIdleTimer();
            return response;
        }, (error) => {
            beginIdleTimer();
            return Promise.reject(error);
        });
    }, []);

    return (
        <React.Fragment>
            {showTimeoutModalState &&
                <Modal show={showTimeoutModalState} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            <h3 className='text-dark'>
                                Idle Timeout Warning
                            </h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='text-dark'>
                            Your session will end in <b>{displayMinutesLeft} : {displaySecondsLeft}</b>, 
                            click continue to extend your session.
                        </h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            className='text-aa-black'
                            onClick={endSession}
                            variant='light' 
                        >
                            Log Out
                        </Button>

                        <Button 
                            onClick={continueSession}
                            variant='primary' 
                        >
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default TimeoutModal;