/* eslint-disable react/prop-types */
import React, {createContext, useContext, useEffect, useState} from 'react';

import {useAppContext} from 'contexts/AppContext';
import getConsentCheck from 'services/directdeposit/getConsentCheck';
import getExistingBankAccounts from 'services/directdeposit/getExistingBankAccounts';
import postEditedAccounts from 'services/directdeposit/postEditedAccounts';
import withLoading from 'utilities/withLoading/withLoading';
import { forEach } from 'lodash';
import { element } from 'prop-types';

export const useDirectDepositContext = () => useContext(DirectDepositContext);
const DirectDepositContext = createContext();

const DirectDepositProvider = (props) => {
    const {isTokenExpired, resetErrorDetails, setErrorDetails} = useAppContext();

    const [consent, setConsent] = useState(false);
    const [editedAccounts, setEditedAccounts] = useState();
    const [employeeDDPData, setEmployeeDDPData] = useState();
    const [existingAccounts, setExistingAccounts] = useState();
    const [isAddView, setIsAddView] = useState(false);
    const [isDisagree, setDisagree] = useState(false);
    const [isEditView, setIsEditView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOutage, setIsOutage] = useState(false);
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const maxNumberOfAccounts = 6;
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
    const [isSubmitEditError, setIsSubmitEditError] = useState(false);

    //to have formated table view checking isDDPMobile at 1053px for this page only 
    const checkIfMobile = () => {
        const maxMobileWidth = 1199;
        if (window.innerWidth <= maxMobileWidth) {
            return true;
        }
        return false;
    };
    
    const {children} = props;
    const [isDDPMobile, setisDDPMobile] = useState(checkIfMobile());

    const bankTypeText = {
        mainBank: 'Main Bank',
        payroll: 'Payroll',
    };
    
    const required = {
        value: true,
        message: 'This field is required',
    };

    const handleLoading = (func) => withLoading(setIsLoading, isLoading, func);

    const consentCheck = async () => {
        const expired = await isTokenExpired();
        if (!expired) {
            resetErrorDetails();
            const res = await getConsentCheck();
            if (res?.isError) {
                if (res?.errorResponseStatusCode === 403) {
                    setIsUnauthorized(true);
                } else {
                    setErrorDetails(res);
                }
            } else if (res?.isOutage) {
                setIsOutage(true);
            }
        }   
    };

    const fetchExistingAccounts = async () => {
        resetErrorDetails();
        setEditedAccounts();
        setExistingAccounts();

        const res = await getExistingBankAccounts();
        if (res?.isError) {
            if (res?.errorResponseStatusCode === 403) {
                setIsUnauthorized(true);
            } else {
                setErrorDetails(res);
            }
        } else if (res?.isOutage) {
            outageFlagSet();  
        } else {
            const accountData = res?.accountData?.currentBankDataList;
            const {employeeData} = res?.accountData;

            setEmployeeDDPData({employeeData});
            setExistingAccounts(accountData);
            const adjustedAccounts = accountData && checkMisOrderedNullAmount(accountData);
            setEditedAccounts(adjustedAccounts);
        };
    };

    const checkMisOrderedNullAmount = (accounts) => {
        const arrCopy = [...accounts];
        accounts.forEach((account, index) => {
            if (!account?.amount) {
                const remainderAccount = account;
                arrCopy.splice(index, 1);
                arrCopy.push(remainderAccount);
            };
        }); 

        return updateAccountOrderValues(arrCopy);
    };

    const disableAccountNumberCopyPaste = () => {
        const accountNoInput = document.querySelectorAll('#accountNoInput');
        const confirmAccountNoInput = document.querySelectorAll('#confirmAccountNoInput');

        accountNoInput.forEach((element) => {
            element.addEventListener('copy', event => event.preventDefault());
            element.addEventListener('paste', event => event.preventDefault());
        });

        confirmAccountNoInput.forEach((element) => {
            element.addEventListener('copy', event => event.preventDefault());
            element.addEventListener('paste', event => event.preventDefault());
        });
        
    };

    const isDuplicateBankAccounts = (data) => {
        const itemsFound = {};
        const mappedData = data.flatMap((account) => {
            const relevantData = JSON.stringify([account?.accountNo, account?.bankKey]);
            if (itemsFound[relevantData]) {
                return [];
            }

            itemsFound[relevantData] = true;
            return relevantData;
        });

        return data.length !== mappedData.length;
    };

    const outageFlagSet = () => {
        setIsLoading(false);
        setIsOutage(true);
    };

    const resetEditAccountsView = () => {
        setIsEditView(false);
        const adjustedAccounts = checkMisOrderedNullAmount(existingAccounts);
        setEditedAccounts(adjustedAccounts);
    };

    const saveEditedAccounts = async (editedAccountsFormData) => {
        setIsLoading(true);
        resetErrorDetails();
        setEditedAccounts(editedAccountsFormData);

        const contentBody = {
            employeeData: employeeDDPData.employeeData,
            currentBankDataList: editedAccountsFormData,
        };
        const res = await postEditedAccounts(contentBody);

        if (res?.isError) {
            setIsSubmitEditError(true);
            setErrorDetails(res);
        } else {
            setShowConfirmEditModal(true);
        };
        setIsLoading(false);
    };

    const updateAccountOrderValues = (accounts) => {
        const orderedAccounts = accounts.map((bankAccount, index) => {
            const accountCopy = {...bankAccount};
            const order = (index + 1).toString();
            accountCopy.bankOrder = order;

            if (index === accounts.length - 1) {
                accountCopy.bankType = bankTypeText.mainBank;
                accountCopy.amount = null;
            } else {
                accountCopy.bankType = bankTypeText.payroll;
            }

            return accountCopy;
        });
        return orderedAccounts;
    };

    useEffect(() => {
        window.addEventListener(
            'resize', 
            () => setisDDPMobile(checkIfMobile())
        );
        handleLoading(() => consentCheck());
    }, []);

    useEffect(() => {
        if (consent && !isEditView && !isAddView) {
            handleLoading(() => fetchExistingAccounts());
        }
    }, [consent, isEditView]);

    useEffect(() => {
    }, []);

    return (
        <DirectDepositContext.Provider 
            value={{
                bankTypeText, consent, editedAccounts, existingAccounts, checkMisOrderedNullAmount, 
                disableAccountNumberCopyPaste, fetchExistingAccounts, isAddView, isDDPMobile, isDisagree, isDuplicateBankAccounts, 
                isEditView, isLoading, isOutage, maxNumberOfAccounts, required, resetEditAccountsView,
                saveEditedAccounts, setConsent, setEditedAccounts, setExistingAccounts, setIsAddView, setDisagree, setIsEditView,
                setIsLoading, setIsOutage, updateAccountOrderValues, showConfirmEditModal, setShowConfirmEditModal,
                isSubmitEditError, setIsSubmitEditError, isUnauthorized,
            }}
        >
            {children}
        </DirectDepositContext.Provider>
    );
};

export {DirectDepositContext, DirectDepositProvider};