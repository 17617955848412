import React, {useState, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAppContext} from 'contexts/AppContext';
import getCurrentAnnouncement from 'services/getCurrentAnnouncement';
import Loader from '../../Shared/Loader/Loader';

const CurrentAdminAnnouncement = () => {
    const {setErrorDetails} = useAppContext('');
    const [isLoading, setIsLoading] = useState();
    const [currentAnnouncementData, setCurrentAnnouncementData] = useState();

    useEffect(() => {
        getCurrentAnnouncementData();
    }, []);

    const getCurrentAnnouncementData = async () => {
        setIsLoading(true);
        setErrorDetails({
            displayType: undefined,
            errorMessage: undefined,
            supportId: undefined,
        });
        const res = await getCurrentAnnouncement();
        if (res.isError) {
            setErrorDetails(res);
        } else {
            setCurrentAnnouncementData(res.searchAdminBanner.message);
        }
        setIsLoading(false);
    };
    return (
        <React.Fragment>             
            {isLoading && <Loader />}
            {currentAnnouncementData && (
            <container>
                <Row className='border-2px mt-2 pt-2 px-3 no-gutters font-size-16 border-info border-bottom-0'>
                    <Col className='text-left align-self-left text-dark font-weight-bold'>
                        Announcement:
                    </Col>
                </Row>
                <Row className='border-2px pb-2 px-3 no-gutters font-size-16 border-info border-top-0'>
                    <Col className='text-left align-self-left text-dark'>
                        {currentAnnouncementData}
                    </Col>
                </Row>
            </container>               
            )}            
        </React.Fragment>
    );
};

export default CurrentAdminAnnouncement;
