import React from 'react';

const GeneralErrorPage = () => {
    return (
        <React.Fragment>
            <h1>General Error Page</h1>
        </React.Fragment>
    );
};

export default GeneralErrorPage;